import "core-js/modules/es.array.push.js";
import HeaderView from "../components/HeaderView.vue";
import { getpayorder, downorder, getconfigs, cancelorder } from "../common/api";
import { Dialog } from 'vant';
export default {
  name: 'TasksView',
  components: {
    HeaderView,
    'vdialog': Dialog.Component
  },

  data() {
    return {
      orderList: {
        count: 0,
        list: [],
        page: 1,
        limit: 10,
        loading: false,
        finished: false,
        error: false
      },
      detailsList: {
        count: 0,
        list: [],
        page: 1,
        limit: 10,
        loading: false,
        finished: false,
        error: false
      },
      page: "",
      refreshing: false,
      type: "all",
      showPicker: false,
      diagshow: false,
      columns: ["all", "dispatched", "inreview", "reprocess", "success", "fail", "timeout"],
      pricemini: 0,
      pricemax: 100000,
      taskmessage: "",
      radiochecked: "",
      orderid: ""
    };
  },

  created() {
    this.gettaskmessage();
    var tpage = this.$route.params.type;

    if (tpage == "details") {
      this.page = "details";
    } else {
      this.page = "order";
    }
  },

  methods: {
    gettaskmessage() {
      getconfigs({
        keys: 'task_message'
      }).then(res => {
        if (res.code === 200) {
          this.taskmessage = res.data.task_message;
        }
      });
    },

    btnrefresh() {
      if (this.pricemini === "" || this.pricemini < 0) {
        this.pricemini = 0;
      }

      if (this.pricemax === "" || this.pricemax < 0) {
        this.pricemax = 100000;
      }

      if (this.pricemini > this.pricemax) {
        var miniprice = this.pricemax;
        this.pricemax = this.pricemini;
        this.pricemini = miniprice;
      }

      this.onRefresh();
    },

    gocancel(id) {
      this.diagshow = true;
      this.orderid = id;
    },

    confirmSubmit() {
      let remark = "";
      let that = this;

      if (this.radiochecked === "1") {
        remark = "Account information is wrong, Can't pay";
      }

      if (this.radiochecked === "2") {
        remark = "Other reasons";
      }

      cancelorder({
        id: that.orderid,
        remark: remark
      }).then(res => {
        if (res.code === 200) {
          that.$toast.success("Cancel success");
          that.onLoad();
        } else {
          that.$toast.fail(res.errmsg);
        }
      }).catch(err => {
        that.$toast.fail(err);
      });
    },

    goorder(id) {
      this.$router.push({
        path: "/order/" + id
      });
    },

    getdownorder(id) {
      downorder({
        id: id
      }).then(res => {
        if (res.code == 200) {
          this.$router.push("/order/" + id);
        } else {
          this.$toast.fail(res.errmsg);
        }
      }).catch(err => {
        console.log(err);
      });
    },

    formatorderno(item) {
      if (item.remark) {
        return item.remark;
      } else {
        let s = String(item.ctime);
        return s.replace(" ", "").replace(/-/g, "").replace(/:/g, "") + item.id;
      }
    },

    onConfirm(value) {
      this.type = value;
      this.showPicker = false;
      this.onRefresh();
    },

    onLoad() {
      if (this.page === "order") {
        if (this.orderList.finished) {
          this.orderList.page = 1;
          this.orderList.list = [];
          this.refreshing = false;
          this.orderList.finished = false;
        }

        getpayorder({
          type: "undispatched",
          page: this.orderList.page,
          limit: this.orderList.limit,
          min_amount: this.pricemini,
          max_amount: this.pricemax
        }).then(res => {
          if (res.data.result.length < 1) {
            this.orderList.loading = false;
            this.orderList.finished = true;
            this.refreshing = false;
          } else {
            this.orderList.list = this.orderList.list.concat(res.data.result);
            this.orderList.count = res.data.count;
            this.orderList.loading = false;
            this.orderList.finished = this.orderList.list.length >= this.orderList.count;
            this.orderList.page++;
          }
        }).catch(() => {
          this.orderList.loading = false;
          this.orderList.error = true;
        });
      } else if (this.page === "details") {
        if (this.detailsList.finished) {
          this.detailsList.page = 1;
          this.detailsList.list = [];
          this.refreshing = false;
          this.detailsList.finished = false;
        }

        getpayorder({
          page: this.detailsList.page,
          limit: this.detailsList.limit,
          status: this.type,
          type: "myorder"
        }).then(res => {
          if (res.data.result.length < 1) {
            this.detailsList.loading = false;
            this.detailsList.finished = true;
            this.refreshing = false;
          } else {
            this.detailsList.list = this.detailsList.list.concat(res.data.result);
            this.detailsList.count = res.data.count;
            this.detailsList.loading = false;
            this.detailsList.finished = this.detailsList.list.length >= this.detailsList.count;
            this.detailsList.page++;
          }
        }).catch(() => {
          this.detailsList.loading = false;
          this.detailsList.error = true;
        });
      }
    },

    onRefresh() {
      if (this.page === "order") {
        this.orderList.loading = true;
        this.orderList.finished = true;
      } else if (this.page === "details") {
        this.detailsList.finished = true;
        this.detailsList.loading = true;
      }

      this.onLoad();
    },

    gridonclick(item) {
      this.page = item;
    }

  }
};