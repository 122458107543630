import "core-js/modules/es.array.push.js";
import QrcodeVue from 'qrcode.vue';
import useClipboard from "vue-clipboard3";
import { rechargequota } from "../common/api";
import { Dialog } from 'vant';
import { useStore } from "vuex";
import { ref } from 'vue';
export default {
  data() {
    return {
      value: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
      size: 200,
      step: 1
    };
  },

  setup() {
    const store = useStore();
    const orderno = ref("");
    const amount = ref("");
    const usdt_amount = ref("");

    const gotocontanct = () => {
      let cwhatsapp = store.state.siteconfig.customer_whatsapp_account;
      cwhatsapp = cwhatsapp.replace("Please enter your account and the amount to be topped up", "My Oreder:" + orderno.value + " Amount:" + amount.value + " USDT:" + usdt_amount.value);
      window.location.href = "xamla:" + cwhatsapp;
    };

    window.gotocontanct = gotocontanct;
    return {
      gotocontanct,
      amount,
      usdt_amount,
      orderno
    };
  },

  created() {
    this.$store.dispatch('asyncupdatesiteconfig');
    this.contactUrl = this.$store.state.contactUrl;
  },

  components: {
    QrcodeVue
  },
  methods: {
    onChickHome() {
      if (this.step == 1) {
        this.$router.push({
          path: '/'
        });
        return;
      }

      let that = this;
      Dialog.confirm({
        allowHtml: true,
        title: 'Confirm go home',
        message: '<span>Please click <a href="javascript:;" onclick="gotocontanct()">contact customer service </a>after the transfer is completed</span>',
        cancelButtonText: "Cancel",
        cancelButtonColor: "#1989fa",
        confirmButtonText: "Go Home"
      }).then(() => {
        that.$router.push({
          path: '/'
        });
      });
    },

    copy() {
      try {
        const {
          toClipboard
        } = useClipboard();
        let text = this.value;
        toClipboard(text);
        this.$toast("Copy success");
      } catch (e) {
        this.$toast.fail("Copy fail");
      }
    },

    recharge() {
      rechargequota({
        amount: this.amount
      }).then(res => {
        if (res.code == 200) {
          this.value = res.data.tokon_address;
          this.orderno = res.data.orderno;
          this.usdt_amount = res.data.usdt_amount;
          this.step = 2;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    }

  }
};