import "core-js/modules/es.array.push.js";
import useClipboard from "vue-clipboard3";
import { uploadimage, getpayorderinfo, downorder } from "../common/api";
import api_host from "../common/host";
export default {
  data() {
    return {
      orderid: "",
      fileList: [],
      exfileList: [{
        url: "https://imfv.oss-ap-south-1.aliyuncs.com/app/img/walpay001.jpg"
      }, {
        url: "https://imfv.oss-ap-south-1.aliyuncs.com/app/img/walpay002.jpg"
      }, {
        url: "https://imfv.oss-ap-south-1.aliyuncs.com/app/img/walpay003.jpg"
      }],
      orderinfo: {},
      isupload: false,
      shwoexfile: false
    };
  },

  created() {
    this.orderid = this.$route.params.id;
    this.getpayorderinfo();
  },

  methods: {
    backorder() {
      this.$router.push("/tasks/details");
    },

    gohome() {
      this.$router.push("/");
    },

    savepay() {
      if (this.isupload) {
        this.$toast("Picture is Uploading, please wait...");
        return;
      }

      if (this.fileList.length > 2) {
        downorder({
          id: this.orderid,
          payment_voucher: this.fileList[0].message,
          bill_voucher: this.fileList[1].message,
          other_voucher: this.fileList.length > 2 ? this.fileList[2].message : ""
        }).then(res => {
          if (res.code === 200) {
            this.$router.push("/tasks/details");
          } else {
            this.$toast.fail(res.errmsg);
          }
        }).catch(() => {
          this.$toast.fail('Update failed, please try again');
        });
      } else {
        this.$toast.fail("Please upload payment screenshot");
      }
    },

    getpayorderinfo() {
      getpayorderinfo({
        id: this.orderid
      }).then(res => {
        if (res.code !== 200) {
          this.$toast.fail(res.errmsg);
          return;
        }

        if (res.data.payment_voucher) {
          this.fileList.push({
            url: api_host + res.data.payment_voucher,
            message: res.data.payment_voucher
          });
        }

        if (res.data.bill_voucher) {
          this.fileList.push({
            url: api_host + res.data.bill_voucher,
            message: res.data.bill_voucher
          });
        }

        if (res.data.other_voucher) {
          this.fileList.push({
            url: api_host + res.data.other_voucher,
            message: res.data.other_voucher
          });
        }

        this.orderinfo = res.data;
        this.orderinfo.amount.toLocaleString('en-US');
      });
    },

    afterRead(file) {
      console.log(this.fileList);
      file.status = 'uploading';
      file.message = 'Uploading...';
      this.isupload = true;
      let that = this;
      uploadimage({
        imagedata: file.content
      }).then(res => {
        that.isupload = false;

        if (res.code === 200) {
          file.status = 'done';
          file.message = res.data.imgurl;
        } else {
          file.status = 'failed';
          file.message = 'Upload failed';
        }
      }).catch(() => {
        that.isupload = false;
        file.status = 'failed';
        file.message = 'Upload failed';
      });
    },

    beforeRead(file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.$toast.fail('Please upload a file of image type');
        return false;
      }

      return true;
    },

    onOversize() {
      this.$toast.fail("File size exceeds maximum limit");
    },

    copy(text) {
      try {
        const {
          toClipboard
        } = useClipboard();
        toClipboard(text);
        this.$toast("Copy success");
      } catch (e) {
        this.$toast.fail("Copy fail");
      }
    }

  }
};