import 'vant/es/dialog/style';
import HeaderView from "../components/HeaderView.vue";
import { Dialog } from 'vant';
import { getcollectiontoollist, addcollectiontool, getconfigs } from "../common/api";
export default {
  name: 'PaytmView',
  components: {
    HeaderView,
    'vdialog': Dialog.Component
  },

  data() {
    return {
      radiovalue: '1',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      show: false,
      confirm_disabled: false,
      account: "",
      password: "",
      upi: "",
      type: "paytm",
      opentype: {},
      rules: {}
    };
  },

  created() {
    this.$store.dispatch("asyncupdatecwinfo");
    this.getconfigsbyserver();
  },

  methods: {
    getconfigsbyserver() {
      getconfigs({
        keys: 'open_account_type'
      }).then(res => {
        if (res.code === 200) {
          console.log(1111);
          this.opentype = JSON.parse(res.data.open_account_type);
          console.log(222);
          console.log(this.opentype, this.opentype['paytm']);
        } else {
          this.opentype = {
            paytm: true,
            bharatpe: true,
            freecharge: true
          };
        }
      }).catch(() => {
        this.opentype = {
          paytm: true,
          bharatpe: true,
          freecharge: true
        };
      });
    },

    cellClick(position) {
      console.log(position);
    },

    getlink(account) {
      if (account.type == "paytm") {
        return "xaml:{url:paytmlogin,account:" + account.account + "}";
      } else if (account.type == "freecharge") {
        return "xaml:{url:freecharge,id:" + account.id + ",upi:" + account.upi + ",account:" + account.account + "}";
      } else {
        return "";
      }
    },

    deleteaccount(aid) {
      this.$toast.fail("Prohibit deletion " + aid);
    },

    confirmSubmit(action) {
      let that = this;

      if (action === "confirm") {
        if (this.radiovalue == 1 && !this.upi.toLowerCase().endsWith('@paytm')) {
          this.$toast.fail("Please enter the correct UPI ID, eg: 1234567890@paytm");
          return false;
        }

        this.confirm_disabled = true;
        this.$refs.addpaytmaccount.submit();
        setTimeout(() => {
          that.confirm_disabled = false;
        }, 1000);
        return false;
      } else {
        return true;
      }
    },

    onSubmit(formdata) {
      if (this.radiovalue == 1) {
        this.type = "paytm";
      } else if (this.radiovalue == 2) {
        this.type = "bharatpe";
        formdata["password"] = this.password;
      } else if (this.radiovalue == 3) {
        this.type = "freecharge";
      }

      formdata["type"] = this.type;
      formdata["account"] = this.account;
      formdata["upi"] = this.upi;
      addcollectiontool(formdata).then(res => {
        this.confirm_disabled = false;

        if (res.code === 200) {
          this.show = false;
          this.$toast.success("Success");
          this.onRefresh();
        } else {
          this.$toast.fail(res.errmsg);
        }
      });
    },

    onLoad() {
      let that = this;

      if (that.refreshing) {
        that.list = [];
        that.refreshing = false;
      }

      getcollectiontoollist({}).then(res => {
        that.loading = false;
        that.finished = true;

        if (res.code === 200) {
          that.list = res.data;
        } else {
          that.$toast.fail(res.errmsg);
        }
      });
    },

    onRefresh() {
      this.finished = false; // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态

      this.loading = true;
      this.onLoad();
    },

    onAdd() {
      this.show = true;
    }

  }
};