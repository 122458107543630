import HeaderView from "../components/HeaderView.vue";
import { getnewslist, getnewsIddetail } from "../common/api";
export default {
  name: 'NewView',
  components: {
    HeaderView
  },

  data() {
    return {
      newList: {
        count: 0,
        list: [],
        page: 1,
        limit: 10,
        loading: false,
        finished: false,
        error: false
      },
      refreshing: false,
      activeNames: []
    };
  },

  methods: {
    onChange(event) {
      for (let i = 0; i < event.length; i++) {
        var index = event[i];

        for (let j = 0; j < this.newList.list.length; j++) {
          if (index == this.newList.list[j].id) {
            if (this.newList.list[j].content === undefined || this.newList.list[j].content === "") {
              getnewsIddetail(index).then(res => {
                this.newList.list[j].content = res.data.content;
              });
            }
          }
        }
      }
    },

    onLoad() {
      getnewslist({
        page: this.newList.page,
        limit: this.newList.limit,
        type: 'news'
      }).then(res => {
        this.newList.list = this.newList.list.concat(res.data.result);
        this.newList.count = res.data.count;
        this.newList.loading = false;

        if (this.newList.list.length >= this.newList.count) {
          this.newList.finished = true;
        }

        this.newList.page++;
      }).catch(() => {
        this.newList.error = true;
      });
    }

  }
};