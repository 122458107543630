import { createStore } from 'vuex'
import { getsiteconfig, getcwinfo } from "../common/api";


export default createStore({
  state: {
    siteconfig: {},
    cwinfo: {},
  },
  getters: {

  },
  mutations: {
    updatecwinfo(state, val) {
      state.cwinfo = val;
    },
    updatesiteconfig(state, val) {
      state.siteconfig = val;
    },
  },
  actions: {
    asyncupdatecwinfo(store) {
      getcwinfo({}).then((res)=>{
        if(res.code === 200) {
          store.commit('updatecwinfo', res.data);
        }
      });
    },
    asyncupdatesiteconfig(store) {
      getsiteconfig({}).then((res)=>{
        if(res.code === 200) {
          store.commit('updatesiteconfig', res.data);
        }
      })
    }
  },
  modules: {
    
  }
})
