import QrcodeVue from 'qrcode.vue';
import useClipboard from "vue-clipboard3";
export default {
  data() {
    return {
      value: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
      size: 200
    };
  },

  components: {
    QrcodeVue
  },
  methods: {
    copy() {
      try {
        const {
          toClipboard
        } = useClipboard();
        let text = this.value;
        toClipboard(text);
        this.$toast("Copy success");
      } catch (e) {
        this.$toast.fail("Copy fail");
      }
    }

  }
};