import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderView = _resolveComponent("HeaderView");

  const _component_van_cell = _resolveComponent("van-cell");

  const _component_van_collapse_item = _resolveComponent("van-collapse-item");

  const _component_van_collapse = _resolveComponent("van-collapse");

  const _component_van_list = _resolveComponent("van-list");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_HeaderView, {
    title: "News",
    routepath: "/"
  }), _createVNode(_component_van_list, {
    loading: $data.newList.loading,
    "onUpdate:loading": _cache[1] || (_cache[1] = $event => $data.newList.loading = $event),
    error: $data.newList.error,
    "onUpdate:error": _cache[2] || (_cache[2] = $event => $data.newList.error = $event),
    "error-text": "The request failed, click Reload",
    "loading-text": "loading",
    finished: $data.newList.finished,
    "finished-text": "No more data",
    class: "menulist",
    onLoad: $options.onLoad
  }, {
    default: _withCtx(() => [_createVNode(_component_van_cell, {
      border: false,
      class: "menuheard"
    }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.newList.list, item => {
      return _openBlock(), _createBlock(_component_van_collapse, {
        modelValue: $data.activeNames,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.activeNames = $event),
        key: item,
        change: $options.onChange($data.activeNames)
      }, {
        default: _withCtx(() => [_createVNode(_component_van_collapse_item, {
          title: item.name,
          name: item.id
        }, {
          default: _withCtx(() => [_createElementVNode("div", {
            innerHTML: item.content
          }, null, 8, _hoisted_1)]),
          _: 2
        }, 1032, ["title", "name"])]),
        _: 2
      }, 1032, ["modelValue", "change"]);
    }), 128))]),
    _: 1
  }, 8, ["loading", "error", "finished", "onLoad"])], 64);
}