import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import HistoryView from '../views/HistoryView.vue';
import TeamView from '../views/TeamView.vue';
import PaytmView from '../views/PaytmView.vue';
import NewView from '../views/NewView.vue';
import UsdtView from '../views/UsdtView.vue';
import RechargeView from '../views/RechargeView.vue';
import TasksView from '../views/TasksView.vue';
import OrderView from '../views/OrderView.vue';

const routes = [
    {
        path: '/',
        name: 'main',
        component: HomeView
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView
    }, 
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/register/:referral_code',
        name: 'register',
        component: RegisterView
    },
    {
        path: '/history/:type',
        name: 'history',
        component: HistoryView
    },
    {
        path: '/team/:type',
        name: 'team',
        component: TeamView
    },
    {
        path: '/paytm',
        name: 'paytm',
        component: PaytmView
    },     
    {
        path: '/news',
        name: 'news',
        component: NewView
    }, 
    {
        path: '/Usdt',
        name: 'Usdt',
        component: UsdtView
    }, 
    {
        path: '/Recharge',
        name: 'Recharge',
        component: RechargeView
    },
    {
        path: '/tasks',
        name: 'tasks1',
        component: TasksView
    }, 
    {
        path: '/tasks/:type',
        name: 'tasks',
        component: TasksView
    }, 
    {
        path: '/order/:id',
        name: 'order',
        component: OrderView
    }, 
]


const router = createRouter({
    history: createWebHistory(),
    //history: createWebHashHistory(process.env.BASE_URL),
    routes
});


router.beforeEach((to, from, next) => {
    // 判断有没有登录
    if (!localStorage.getItem('token')) {
        if (to.name === "login" || to.name === "register") {
            next();
        } else {
            router.push({"name":"login"});
        }
    } else {
        next();
    }
});

export default router